
export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Courses",
    desc: "We provide courses from zero to master.Our comprehensive courses provide personalized guidance, practical insights, and hands-on experience to help you master programming skills regardless of your age or experience level.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn A Certificates",
    desc: "Earn a certificate through our platform and unlock new opportunities for your future. Gain valuable skills at your own pace and receive recognition for your achievements. Take the first step towards advancing your career today!",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Learn with Expert",
    desc: "Embark your journey with us by learning from experts in the field.Whether you're a curious beginner or a seasoned enthusiast, our expert-led approach ensures that you receive the support and mentorship needed to excel in the world of coding.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]

export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "BASIC",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "STEP-1",

      },
    ],
    sub: [
      "Block Programming",
      "Basic Python",
      "Basic R",
      "MS Excel",
      "Introduction AI",
      "and Many More"
    ],
    priceAll: "₹2000",
    pricePer: "month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Advance",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "STEP-2",
        totalTime: "STEP-2",
      },
    ],
    sub: [
      "Artificial Intelligence",
      "Advance Java",
      "Python",
      "HTML/CSS",
      "Database Management System",
      "and Many More"
    ],
    priceAll: "₹3500",
    pricePer: "month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Advance+",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "STEP-3",
        totalTime: "STEP-3",
      },
    ],
    sub: [
      "AI/ML",
      "Advance Frameworks",
      "Backend Programming",
      "Frontend Programming",
      "Data Science/Analyst",
      "and Many More"
    ],
    priceAll: "₹5000 ",
    pricePer: "month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]
export const certificates = [
  {
    cover: "./images/certificate/iaf.png",
  },
  {
    cover: "./images/certificate/iso.png",
  }
]
export const online = [
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Artificial Intelligence and Machine Learning",
    course: "Enroll Now",
    price: "₹14990 only"
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Data Science and Analytics",
    course: "Enroll Now",
    price: "₹14990 only"
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "DevOps and Agile Practices",
    course: "Enroll Now",
    price: "₹9990 only"
  },
  {
    cover: "./images/courses/online/o14.png",
    hoverCover: "./images/courses/online/o14.1.png",
    courseName: "Internet of Things (IoT)",
    course: "Enroll Now",
    price: "₹11990 only"
  },
  {
    cover: "./images/courses/online/o15.png",
    hoverCover: "./images/courses/online/o15.1.png",
    courseName: "Cloud Computing",
    course: "Enroll Now",
    price: "₹11990 only"
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Generative AI",
    course: "Enroll Now",
    price: "₹10500 only"
  },
  {
    cover: "./images/courses/online/o13.png",
    hoverCover: "./images/courses/online/o13.1.png",
    courseName: "Cyber Security",
    course: "Enroll Now",
    price: "₹9990 only"
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "GitHub",
    course: "Enroll Now",
    price: "₹1499 only"
  },
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "Software Development and Programming",
    course: "Enroll Now",
    price: "₹14990 only"
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Practice Interview with an Expert",
    course: "Enroll Now",
    price: "₹299 only"
  },

]
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "Neha Sharma",
    post: "Sr. Software Engineer",
    desc: "Neha is currently employed at American Express, where she brings over five years of extensive experience in Full Stack development. Throughout her career, she has honed her skills in both front-end and back-end technologies, enabling her to design and implement robust, scalable, and user-friendly applications. Neha's expertise spans various programming languages and frameworks, allowing her to work seamlessly across the entire development lifecycle.",
    cover: "./images/testo/neha.webp",
    linkdin: "https://www.linkedin.com/in/neha-sharma-56b91914a/"
  },
  {
    id: 2,
    name: "Gourav Sharma",
    post: "Software Engineer",
    desc: "Gaurav is a skilled professional specializing in Java and React, with a solid background in software development. His expertise in these technologies has enabled him to build dynamic, responsive, and high-performance web applications that meet modern industry standards. During his tenure at Bharti Airtel, Gaurav was instrumental in developing and maintaining critical software solutions that supported the company’s vast operations. His ability to work with complex systems and deliver robust applications under tight deadlines made him a key contributor to the team.",
    cover: "./images/testo/gu.webp",
    linkdin: "https://www.linkedin.com/in/gourav-sharma-3662b1185/"
  },
  {
    id: 3,
    name: "Satyam Pandey",
    post: "Software Engineer",
    desc: "Satyam is a software developer at Panamoure with a strong expertise in Data Science, Artificial Intelligence, and the Microsoft Power Platform, for which he holds a certification. His technical skills enable him to develop sophisticated data-driven solutions and leverage AI to enhance business processes. Satyam's proficiency in these cutting-edge technologies makes him a valuable asset to his team, driving innovation and efficiency in the projects he undertakes.",
    cover: "./images/testo/sat.webp",
    linkdin: "https://www.linkedin.com/in/satyam-pandey-2b05741ba/"
  },
  {
    id: 3,
    name: "Sheelender Sharma",
    post: "Senior Advisor",
    desc: "Sheelender is a certified Data Science engineer with a specialization in Artificial Intelligence. His expertise in AI allows him to develop advanced algorithms and models that drive data-driven decision-making and innovation. Sheelender's strong foundation in Data Science, combined with his AI specialization, positions him as a key contributor in the field, capable of tackling complex challenges and delivering impactful solutions.",
    cover: "./images/testo/shilu.webp",
    linkdin: "https://www.linkedin.com/in/sheelendersharma/"
  },
]
